@font-face {
  font-family: Roboto-Regular;
  src: local(Roboto-Regular),
    url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.waiting-order {
  width: 960px;
  height: 464px;
  display: flex;
  justify-content: center;
  align-items: center;
  .waiting-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .waiting-pic {
      width: 280px;
      height: 160px;
    }
    .waiting-messsage {
      font-family: Roboto-Regular;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: normal;
      text-align: center;
      color: #333333;
    }
  }
}
