.login {
  width: 960px;
  height: 550px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(51, 51, 51);
  margin: auto;
}

.form-control.error {
  border: 1px solid red !important;
}

.loading-request {
  color: #a6aaae;
  font-size: 14px;
  font-family: cursive;
}
