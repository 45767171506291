.main {
  background-image: url("../images/main.png");
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 100%;
  .message {
    justify-content: center;
  }
}
