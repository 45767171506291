@font-face {
  font-family: Roboto-Regular;
  src: local(Roboto-Regular), url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

body {
  margin: 0;
  padding: 0;
}

.header {
  justify-content: center;
  background-color: #333333;
  align-items: center;
  display: flex;
  height: 80px;
  width: 100%;

  .settings {
    cursor: pointer;
    img {
      width: 52px;
      height: 40px;
    }
  }

  .logout {
    cursor: pointer;
    margin-left: 10px;
    img {
      mix-blend-mode: multiply;
      width: 36px;
      height: 30px;
    }
  }

  .logo {
    margin: 20px;
    .logo__img {
      width: 132px;
      height: 64px;
    }
  }

  .search {
    width: 630px;
    height: 48px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    background-color: white;

    .search__input {
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        margin: 12px;
      }
      & input {
        width: 100%;
        border: none;
        font-family: Roboto-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
        outline: none;
        &::placeholder {
          color: #bcbcbc;
        }
      }
    }
  }

  .info {
    margin: 0 15px;
    .info__all {
      margin: 0;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #ffffff;
    }
    .info__hot {
      margin: 0;
      font-family: Roboto-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #ffeea0;
    }
  }
}
