@font-face {
  font-family: Roboto-Medium;
  src: local(Roboto-Medium), url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Regular;
  src: local(Roboto-Regular),
    url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.message {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .welcome-message {
    font-family: Roboto-Medium;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin: 0;
  }
  .info-message {
    font-family: Roboto-Regular;
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin: 28px 0 38px;
  }
  .submit-message {
    button {
      width: 146px;
      height: 44px;
      border: solid 3px #147e00;
      background-color: #ffffff;
      font-family: Roboto-Medium;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #008006;
      outline: none;
    }
  }
}
