.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;
  img {
    width: 200px;
    height: 200px;
    filter: hue-rotate(190deg);
  }
}
