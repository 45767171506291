.loading-app {
  width: 960px;
  height: 464px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 360px;
      height: 360px;
    }
  }
}
