@font-face {
  font-family: Roboto-Medium;
  src: local(Roboto-Medium), url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Regular;
  src: local(Roboto-Regular), url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.orders-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 960px;
  padding: 10px 10px;
}

.order-toClient {
  border: solid 2px #000000 !important;
  background-color: rgba(255, 86, 0, 0.35) !important;
}

.tap1 {
  border: solid 5px #df330f !important;
  background-color: rgba(255, 215, 0, 0.45) !important;
  padding: 7px !important;
}

.tap2 {
  border: solid 2px #000000 !important;
  background-color: #cceab5 !important;
}

.tap2-finish {
  opacity: 0;
  transition: 1s;
}

.card {
  width: 180px;
  height: 105px;
  border: solid 2px #000000;
  background-color: #ffffff;
  margin: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .card__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .card__id {
      font-family: Roboto-Medium;
      font-size: 40px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
    }

    .card__time {
      font-family: Roboto-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: right;
      color: #4a4a4a;
      margin: 6px;
    }
  }
  .card__bottom {
    .card__workshops {
      img:not(:last-child) {
        margin-right: 4px;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
