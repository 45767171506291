@font-face {
  font-family: Roboto-Medium;
  src: local(Roboto-Medium), url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.modal-settings {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 400px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    border: 2px solid black;
    border-radius: 4px;
    flex-direction: column;
    position: relative;
    #x {
      font-family: "Helvetica", "Arial", sans-serif;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 0px;
      right: 3px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    .modal-settings__text {
      font-family: Roboto-Medium;
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin: 0;
    }
    .modal-settings__info {
      font-family: Roboto-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      margin: 0;
      position: absolute;
      bottom: 1px;
      right: 5px;
    }
  }
}

.tablets {
  display: flex;
  justify-content: center;
  align-items: center;
  .tablet {
    display: flex;
    position: relative;
    margin: 10px;
    transition: filter 0.4s ease;
    cursor: pointer;
    &:hover {
      filter: sepia(1);
    }
    .tablet__num {
      position: absolute;
      top: 28%;
      left: 40%;
      font-size: 20px;
      font-weight: 900;
      z-index: 1;
    }
    img {
      width: 50px;
    }
  }
}
